<template>
  <PageTemplate>
		<Grid>
			<GridContainer size="12">
				<Heading size="3">Image</Heading>
			</GridContainer>
			<GridContainer size="12 9@lg">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Sizes</Heading>
							<Paragraph>Add height=" " and width=" " props to set a custom height or width. This can be any web measurement ex. 100px, 50%, 10pc.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stack direction="column row@sm" space="2">
							<Stack width="200px">
								<Stack direction="column" width="200px" space="1">
									<Stage>
										<Images height="300px" width="200px" image="theme-ds/size1.jpg" />
									</Stage>
									<Paragraph>Height: 300px</Paragraph>
									<Paragraph>Width: 200px</Paragraph>
								</Stack>
							</Stack>
							<Stack width="300px">
								<Stack direction="column" width="300px" space="1">
									<Stage>
										<Images height="200px" width="300px" image="theme-ds/size2.jpg" />
									</Stage>
									<Paragraph>Height: 200px</Paragraph>
									<Paragraph>Width: 300px</Paragraph>
								</Stack>
							</Stack>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12 9@lg">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Variations</Heading>
							<Paragraph>An image may appear circular. Add the circle prop.</Paragraph>
							<Paragraph>An image may include a border to emphasize the edges. Add the border prop.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stack direction="column row@sm" space="2">
							<Stack width="150px">
								<Stage>
									<Images height="150px" width="150px" image="theme-ds/variations.jpg" circle />
								</Stage>
							</Stack>
							<Stack width="150px">
								<Stage>
									<Images height="150px" width="150px" image="theme-ds/variations.jpg" border />
								</Stage>
							</Stack>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12">
				<Stack direction="column" align="left" width="100%" space="2">
					<Heading size="3">Slider</Heading>
					<Paragraph>A slider component is for cycling through elements like a carousel.</Paragraph>
				</Stack>
			</GridContainer>
			<GridContainer size="12 6@lg">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Image Slider</Heading>
							<Paragraph>With the slider and slider containers, turn any content into a slider.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stack width="100%">
							<Stage>
								<Slider time="10000" height="370px" type="nav">
									<SliderContainer>
										<Images height="350px" width="100%" image="theme-ds/slide1.jpg" />
									</SliderContainer>
									<SliderContainer>
										<Images height="350px" width="100%" image="theme-ds/slide2.jpg" />
									</SliderContainer>
									<SliderContainer>
										<Images height="350px" width="100%" image="theme-ds/slide3.jpg" />
									</SliderContainer>
								</Slider>
							</Stage>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12 6@lg">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Component Slider</Heading>
							<Paragraph>There are 3 different navigation types for the sliders: nav, dots and buttons.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stack width="100%">
							<Stage>
								<Slider time="8000" height="350px" type="dots">
									<SliderContainer>
										<Stack direction="column" align="center" justify="center" space="2" width="100%" height="300px">
											<Icon icon="grid_view" size="large" color="light" background="primary" />
											<Heading size="3" align="center">Any components can go into a slider...</Heading>
											<Paragraph align="center" micro>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sit amet magna fermentum, tempus est at, consequat sem. Vivamus egestas augue dui, vestibulum tempor ante suscipit et.</Paragraph>
										</Stack>
									</SliderContainer>
									<SliderContainer>
										<Stack direction="column" align="center" justify="center" space="2" width="100%" height="300px">
											<Icon icon="photo_camera" size="large" color="light" background="secondary" />
											<Heading size="3" align="center">...not just images!</Heading>
											<Paragraph align="center" micro>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sit amet magna fermentum, tempus est at, consequat sem. Vivamus egestas augue dui, vestibulum tempor ante suscipit et.</Paragraph>
										</Stack>
									</SliderContainer>
								</Slider>
							</Stage>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Heading size="5">Images Properties</Heading>
					</CardSection>
					<CardSection>
						<Table>
							<TableRow head>
								<TableCell>
									<Paragraph margin="none" bold>Property</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Type</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Options</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Explanation</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">image</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Sets the location of a local image.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">height</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Defines the height of the image.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">width</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Defines the width of the image.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">circle</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">Boolean</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Adds the circle styles to the image.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">border</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">Boolean</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Adds a border to the image.</Paragraph>
								</TableCell>
							</TableRow>
						</Table>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Heading size="5">Slider Properties</Heading>
					</CardSection>
					<CardSection>
						<Table>
							<TableRow head>
								<TableCell>
									<Paragraph margin="none" bold>Property</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Type</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Options</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Explanation</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">time</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">The amount of time between slides. Must be in milliseconds ex. 10000 = 10 seconds</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">height</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Defines the height of the slider. Must be px.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">type</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">
										nav<br/>
										dots<br/>
										buttons
									</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Sets the sliders navigation type.</Paragraph>
								</TableCell>
							</TableRow>
						</Table>
					</CardSection>
				</Card>
			</GridContainer>
		</Grid>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/designsystem/template/Page'; 
export default {
  components: {
		PageTemplate
  },
  data() {
    return {
      theme: ''
		}
  },
  mounted() {
		this.stageTheme(this.theme);
  },
}
</script>

<style lang="scss" scoped>

</style>
